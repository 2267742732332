import React, { useRef, useState,Suspense  } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { Provider } from "react-redux";

import Keyboard from "react-simple-keyboard";
import appIcon from "./assets/img/faviconicon.png";
import appBackground from "./assets/img/bg42.png";

import MainMenu from "./components/MainMenu";
import Home from "./components/Home"
import Lesson from "./components/Lesson";
import Test from "./components/Test";
import Game from "./components/Game";
import {Container, Row, Col} from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import LanguageSwitcher from "./components/LanguageSwitcher";
import lesson from './data/lessons.json';


// import DzongkhaKeyboard from "../keyboard/layouts/dzongkha";


export default function App() {

  const {t, i18n} = useTranslation();
  const selectedCountry = (localStorage.getItem('lng')==="en")? "US":"BT";
  // const  = useState((localStorage.getItem('font'))? localStorage.getItem('font'): "wangdi29")

  const [dzFontFamily, setDzFontFamily] = useState((localStorage.getItem('lng')==="dz")? ((localStorage.getItem('font'))? localStorage.getItem('font'): "wangdi29"):"");

  const changeFont = () => {
    if(localStorage.getItem('lng')=="dz")
      setDzFontFamily((localStorage.getItem('font')? localStorage.getItem('font'): "wangdi29"));
    else
      setDzFontFamily("");
  }
  return (

      <Suspense fallback="loading">
        <Router>
          <Container fluid className={i18n.language}>
            <Row>
              <Col>
              <Container >
                <div className="title-container">
                  <div>
                  <p className={`title `+dzFontFamily}>
                  {t('title')}
                  </p>
                  </div>
                </div>
              {/* <LanguageSwitcher />
              <div className="menu-container">
              <MainMenu/> */}
              <div className="menu-container">
                <div className="row">
                  <div className="col-8">
                  <div className="menu-container">
                        <MainMenu fontFamily={dzFontFamily}/>
                        </div>
                  </div>
                  <div className="col-4" style={{textAlign:"right"}}>
                      <LanguageSwitcher fontFamily={dzFontFamily} changeFont={changeFont}/>
                  </div>
                </div>
              </div>
              
              <Switch>
                <Route exact path='/' component={Home} />
                {/* <Route path='/basic' component={Lesson(changeFont)} /> */}
                <Route
                  path='/basic/alphabets/lesson1/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.basic.alphabets.lesson1.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/alphabets/lesson1/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.alphabets.lesson1.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/alphabets/lesson2/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.basic.alphabets.lesson2.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/alphabets/lesson2/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.alphabets.lesson2.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/alphabets/lesson3/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.basic.alphabets.lesson3.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/alphabets/lesson4/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.basic.alphabets.lesson4.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/alphabets/lesson5/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.basic.alphabets.lesson5.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/alphabets/lesson6/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.basic.alphabets.lesson6.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/alphabets/lesson7/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.basic.alphabets.lesson7.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/alphabets/lesson8/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.basic.alphabets.lesson8.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/vowels/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.basic.vowels.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/dhogchen/yatak/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.basic.dhogchen.yatak.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/dhogchen/ratak/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.basic.dhogchen.ratak.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/dhogchen/watak/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.basic.dhogchen.watak.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/dhogchen/latak/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.basic.dhogchen.latak.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/dhogchen/rago/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.basic.dhogchen.rago.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/dhogchen/lago/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.basic.dhogchen.lago.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/dhogchen/sago/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.basic.dhogchen.sago.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/numbers'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.basic.numbers.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/logpaiyigu/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.basic.logpaiyigu.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/thugpaiyigu/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.basic.thugpaiyigu.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/gogen/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.basic.gogen.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/intermediate/colors/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.intermediate.colors.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/intermediate/bodyparts/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.intermediate.bodyparts.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/intermediate/fruits/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.intermediate.fruits.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/intermediate/vegetables/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.intermediate.vegetables.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/intermediate/days/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.intermediate.days.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/intermediate/animals/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.intermediate.animals.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/intermediate/numbers/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.intermediate.numbers.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/songs/song1/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.advanced.songs.song1.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/songs/song2/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.advanced.songs.song2.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/songs/song3/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.advanced.songs.song3.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/tsangmo/tsangmo1/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.advanced.tsangmo.tsangmo1.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/tsangmo/tsangmo2/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.advanced.tsangmo.tsangmo2.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/tsangmo/tsangmo3/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.advanced.tsangmo.tsangmo3.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/lozey/lozey1/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.advanced.lozey.lozey1.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/lozey/lozey2/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.advanced.lozey.lozey2.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/lozey/lozey3/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.advanced.lozey.lozey3.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/peytam/peytam1/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.advanced.peytam.peytam1.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/peytam/peytam2/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.advanced.peytam.peytam2.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/peytam/peytam3/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.advanced.peytam.peytam3.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/peytam/peytam4/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.advanced.peytam.peytam4.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/peytam/peytam5/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.advanced.peytam.peytam5.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/ayig/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.advanced.ayig.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/story/story1/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.advanced.story.story1.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/story/story2/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.advanced.story.story2.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/story/story3/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.advanced.story.story3.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/story/story4/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.advanced.story.story4.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/story/story5/practice'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.advanced.story.story5.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/competitions'
                  render={(props) => (
                    <Lesson {...props} lessonContent={lesson.basic.alphabets.lesson1.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />



                {/* Route for tests */}
                <Route
                  path='/basic/alphabets/lesson1/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.alphabets.lesson1.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/alphabets/lesson2/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.alphabets.lesson2.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/alphabets/lesson3/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.alphabets.lesson3.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/alphabets/lesson4/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.alphabets.lesson4.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/alphabets/lesson5/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.alphabets.lesson5.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/alphabets/lesson6/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.alphabets.lesson6.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/alphabets/lesson7/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.alphabets.lesson7.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/alphabets/lesson8/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.alphabets.lesson8.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/vowels/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.vowels.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/dhogchen/yatak/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.dhogchen.yatak.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/dhogchen/ratak/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.dhogchen.ratak.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/dhogchen/watak/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.dhogchen.watak.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/dhogchen/latak/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.dhogchen.latak.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/dhogchen/rago/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.dhogchen.rago.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/dhogchen/lago/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.dhogchen.lago.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/dhogchen/sago/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.dhogchen.sago.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/numbers'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.numbers.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/logpaiyigu/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.logpaiyigu.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/thugpaiyigu/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.thugpaiyigu.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/basic/gogen/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.basic.gogen.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/intermediate/colors/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.intermediate.colors.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/intermediate/bodyparts/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.intermediate.bodyparts.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/intermediate/fruits/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.intermediate.fruits.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/intermediate/vegetables/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.intermediate.vegetables.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/intermediate/days/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.intermediate.days.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/intermediate/animals/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.intermediate.animals.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/intermediate/numbers/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.intermediate.numbers.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/songs/song1/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.advanced.songs.song1.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/songs/song2/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.advanced.songs.song2.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/songs/song3/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.advanced.songs.song3.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/tsangmo/tsangmo1/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.advanced.tsangmo.tsangmo1.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/tsangmo/tsangmo2/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.advanced.tsangmo.tsangmo2.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/tsangmo/tsangmo3/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.advanced.tsangmo.tsangmo3.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/lozey/lozey1/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.advanced.lozey.lozey1.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/lozey/lozey2/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.advanced.lozey.lozey2.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/lozey/lozey3/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.advanced.lozey.lozey3.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/peytam/peytam1/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.advanced.peytam.peytam1.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/peytam/peytam2/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.advanced.peytam.peytam2.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/peytam/peytam3/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.advanced.peytam.peytam3.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/peytam/peytam4/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.advanced.peytam.peytam4.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/peytam/peytam5/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.advanced.peytam.peytam5.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/ayig/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.advanced.ayig.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/story/story1/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.advanced.story.story1.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/story/story2/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.advanced.story.story2.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/story/story3/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.advanced.story.story3.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/story/story4/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.advanced.story.story4.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
                <Route
                  path='/advanced/story/story5/test'
                  render={(props) => (
                    <Test {...props} lessonContent={lesson.advanced.story.story5.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />


                {/* Routes for games */}
                <Route
                  path='/basic/alphabets/lesson1/game'
                  render={(props) => (
                    <Game {...props} lessonContent={lesson.basic.alphabets.lesson1.lesson} changeFont={changeFont} fontFamily={dzFontFamily}/>
                  )}
                />
              </Switch>
              </Container>
              </Col>
            </Row>
          </Container>
        </Router>
      </Suspense>
  );
}