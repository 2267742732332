import React, { useState, useEffect, useRef } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import {useTranslation} from "react-i18next";


function MainMenu(props){
  const {t, i18n} = useTranslation();
  const fontFamily = props.fontFamily;
  const [fontFamilyClass, setFontFamilyClass] = useState(props.fontFamily);

  return(
    <>
      <div className={fontFamily}>
      <Nav>
        <Nav.Link href="/">{t("home")}</Nav.Link>
        <NavDropdown title={t("basic")} id="basic-nav-dropdown">
          <NavDropdown title={t("alphabets")} id="" className="dropdown-submenu-level-1">
            <NavDropdown title={t("lesson1")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/basic/alphabets/lesson1/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/alphabets/lesson1/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/alphabets/lesson1/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("lesson2")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/basic/alphabets/lesson2/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/alphabets/lesson2/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/alphabets/lesson2/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("lesson3")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/basic/alphabets/lesson3/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/alphabets/lesson3/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/alphabets/lesson2/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("lesson4")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/basic/alphabets/lesson4/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/alphabets/lesson4/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/alphabets/lesson2/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("lesson5")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/basic/alphabets/lesson5/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/alphabets/lesson5/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/alphabets/lesson2/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("lesson6")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/basic/alphabets/lesson6/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/alphabets/lesson6/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/alphabets/lesson2/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("lesson7")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/basic/alphabets/lesson7/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/alphabets/lesson7/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/alphabets/lesson2/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("lesson8")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/basic/alphabets/lesson8/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/alphabets/lesson8/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/alphabets/lesson8/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
          </NavDropdown>
          <NavDropdown title={t("vowels")} id="" className="dropdown-submenu-level-1">
            <NavDropdown.Item href="/basic/vowels/practice">{t("practice")}</NavDropdown.Item>
            <NavDropdown.Item href="/basic/vowels/test">{t("test")}</NavDropdown.Item>
            <NavDropdown.Item href="/basic/vowels/game">{t("game")}</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title={t("dhogchen")} id="" className="dropdown-submenu-level-1">
            <NavDropdown title={t("yatak")} id="" className="dropdown-submenu">
              <NavDropdown.Item href="/basic/dhogchen/yatak/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/dhogchen/yatak/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/dhogchen/yatak/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("ratak")} id="" className="dropdown-submenu">
              <NavDropdown.Item href="/basic/dhogchen/ratak/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/dhogchen/ratak/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/dhogchen/ratak/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("watak")} id="" className="dropdown-submenu">
              <NavDropdown.Item href="/basic/dhogchen/watak/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/dhogchen/watak/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/dhogchen/watak/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("latak")} id="" className="dropdown-submenu">
              <NavDropdown.Item href="/basic/dhogchen/latak/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/dhogchen/latak/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/dhogchen/latak/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
          </NavDropdown>
          <NavDropdown title={t("gochen")} id="" className="dropdown-submenu-level-1">
            <NavDropdown title={t("rago")} id="" className="dropdown-submenu">
              <NavDropdown.Item href="/basic/gochen/rago/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/alphabets/lesson1/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/alphabets/lesson2/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("lago")} id="" className="dropdown-submenu">
              <NavDropdown.Item href="/basic/gochen/lago/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/gochen/lago/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/gochen/lago/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("sago")} id="" className="dropdown-submenu">
              <NavDropdown.Item href="/basic/gochen/sago/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/gochen/sago/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/basic/gochen/sago/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
          </NavDropdown>
          <NavDropdown title={t("numbers")} id="" className="dropdown-submenu-level-1">
            <NavDropdown.Item href="/basic/numbers/practice">{t("practice")}</NavDropdown.Item>
            <NavDropdown.Item href="/basic/numbers/test">{t("test")}</NavDropdown.Item>
            <NavDropdown.Item href="/basic/numbers/game">{t("game")}</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title={t("logpaiyigu")} id="" className="dropdown-submenu-level-1">
            <NavDropdown.Item href="/basic/logpaiyigu/practice">{t("practice")}</NavDropdown.Item>
            <NavDropdown.Item href="/basic/alphabets/lesson1/test">{t("test")}</NavDropdown.Item>
            <NavDropdown.Item href="/basic/alphabets/lesson2/game">{t("game")}</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title={t("thugpaiyigu")} id="" className="dropdown-submenu-level-1">
            <NavDropdown.Item href="/basic/thugpaiyigu/practice">{t("practice")}</NavDropdown.Item>
            <NavDropdown.Item href="/basic/thugpaiyigu/test">{t("test")}</NavDropdown.Item>
            <NavDropdown.Item href="/basic/thugpaiyigu/game">{t("game")}</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title={t("gogen")} id="" className="dropdown-submenu-level-1">
            <NavDropdown.Item href="/basic/gogen/practice">{t("practice")}</NavDropdown.Item>
            <NavDropdown.Item href="/basic/gogen/test">{t("test")}</NavDropdown.Item>
            <NavDropdown.Item href="/basic/gogen/game">{t("game")}</NavDropdown.Item>
          </NavDropdown>
        </NavDropdown>
        <NavDropdown title={t("intermediate")} id="nav-dropdown-2">
          <NavDropdown title={t("colors")} id="" className="dropdown-submenu-level-1">
            <NavDropdown.Item href="/intermediate/colors/practice">{t("practice")}</NavDropdown.Item>
            <NavDropdown.Item href="/intermediate/colors/test">{t("test")}</NavDropdown.Item>
            <NavDropdown.Item href="/intermediate/colors/game">{t("game")}</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title={t("bodyparts")} id="" className="dropdown-submenu-level-1">
            <NavDropdown.Item href="/intermediate/bodyparts/practice">{t("practice")}</NavDropdown.Item>
            <NavDropdown.Item href="/intermediate/bodyparts/test">{t("test")}</NavDropdown.Item>
            <NavDropdown.Item href="/intermediate/bodyparts/game">{t("game")}</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title={t("fruits")} id="" className="dropdown-submenu-level-1">
            <NavDropdown.Item href="/intermediate/fruits/practice">{t("practice")}</NavDropdown.Item>
            <NavDropdown.Item href="/intermediate/fruits/test">{t("test")}</NavDropdown.Item>
            <NavDropdown.Item href="/intermediate/fruits/game">{t("game")}</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title={t("vegetables")} id="" className="dropdown-submenu-level-1">
            <NavDropdown.Item href="/intermediate/vegetables/practice">{t("practice")}</NavDropdown.Item>
            <NavDropdown.Item href="/intermediate/vegetables/test">{t("test")}</NavDropdown.Item>
            <NavDropdown.Item href="/intermediate/vegetables/game">{t("game")}</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title={t("days")} id="" className="dropdown-submenu-level-1">
            <NavDropdown.Item href="/intermediate/days/practice">{t("practice")}</NavDropdown.Item>
            <NavDropdown.Item href="/intermediate/days/test">{t("test")}</NavDropdown.Item>
            <NavDropdown.Item href="/intermediate/days/game">{t("game")}</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title={t("animals")} id="" className="dropdown-submenu-level-1">
            <NavDropdown.Item href="/intermediate/animals/practice">{t("practice")}</NavDropdown.Item>
            <NavDropdown.Item href="/intermediate/animals/test">{t("test")}</NavDropdown.Item>
            <NavDropdown.Item href="/intermediate/animals/game">{t("game")}</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title={t("numbers")} id="" className="dropdown-submenu-level-1">
            <NavDropdown.Item href="/intermediate/numbers/practice">{t("practice")}</NavDropdown.Item>
            <NavDropdown.Item href="/intermediate/numbers/test">{t("test")}</NavDropdown.Item>
            <NavDropdown.Item href="/intermediate/numbers/game">{t("game")}</NavDropdown.Item>
          </NavDropdown>
        </NavDropdown>
        <NavDropdown title={t("advanced")} id="nav-dropdown-2">
          <NavDropdown title={t("songs")} id="" className="dropdown-submenu-level-1">
            <NavDropdown title={t("song1")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/advanced/songs/song1/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/songs/song1/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/songs/song1/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("song2")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/advanced/songs/song2/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/songs/song2/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/songs/song2/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("song3")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/advanced/songs/song3/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/songs/song3/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/songs/song3/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
          </NavDropdown>
          <NavDropdown title={t("tsangmo")} id="" className="dropdown-submenu-level-1">
            <NavDropdown title={t("tsangmo1")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/advanced/tsangmo/tsangmo1/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/tsangmo/tsangmo1/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/tsangmo/tsangmo1/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("tsangmo2")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/advance/tsangmo/tsangmo2/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/advance/tsangmo/tsangmo2/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/advance/tsangmo/tsangmo2/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("tsangmo3")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/advance/tsangmo/tsangmo3/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/advance/tsangmo/tsangmo3/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/advance/tsangmo/tsangmo3/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
          </NavDropdown>
          <NavDropdown title={t("lozey")} id="" className="dropdown-submenu-level-1">
            <NavDropdown title={t("lozey1")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/advanced/lozey/lozey1/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/lozey/lozey1/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/lozey/lozey1/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("lozey2")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/advanced/lozey/lozey2/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/lozey/lozey2/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/lozey/lozey2/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("lozey3")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/advanced/lozey/lozey3/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/lozey/lozey3/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/lozey/lozey3/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
          </NavDropdown>
          <NavDropdown title={t("peytam")} id="" className="dropdown-submenu-level-1">

            <NavDropdown title={t("peytam1")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/advanced/peytam/peytam1/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/peytam/peytam1/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/peytam/peytam1/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("peytam2")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/advanced/peytam/peytam2/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/peytam/peytam2/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/peytam/peytam2/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("peytam3")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/advanced/peytam/peytam3/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/peytam/peytam3/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/peytam/peytam3/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
          </NavDropdown>
          <NavDropdown title={t("ayig")} id="" className="dropdown-submenu-level-1">
            <NavDropdown.Item href="/advanced/ayig/practice">{t("practice")}</NavDropdown.Item>
            <NavDropdown.Item href="/advanced/ayig/test">{t("test")}</NavDropdown.Item>
            <NavDropdown.Item href="/advanced/ayig/game">{t("game")}</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title={t("story")} id="" className="dropdown-submenu-level-1">
            <NavDropdown title={t("story1")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/advanced/story/story1/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/story/story1/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/story/story1/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("story2")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/advanced/story/story2/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/story/story2/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/story/story2/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("story3")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/advanced/story/story3/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/story/story3/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/story/story3/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("story4")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/advanced/story/story4/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/story/story4/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/story/story4/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={t("story5")} id="" className="dropdown-submenu-level-2">
              <NavDropdown.Item href="/advanced/story/story5/practice">{t("practice")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/story/story5/test">{t("test")}</NavDropdown.Item>
              <NavDropdown.Item href="/advanced/story/story5/game">{t("game")}</NavDropdown.Item>
            </NavDropdown>
          </NavDropdown>
        </NavDropdown>
        <Nav.Link href="/competitions">{t("competitions")}</Nav.Link>
      </Nav>
      </div>
      </>
  )
}
export default MainMenu;