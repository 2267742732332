import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_dz from "./translations/dz/common.json";
import common_en from "./translations/en/common.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
      common: common_en
    },
  dz: {
    common:common_dz
  },
};
// pulls already set language from localstorage
i18n
.use(initReactI18next)
.init({
  // we init with resources
  resources,
  lng: localStorage.getItem('lng'),
  fallbackLng: "dz",
  debug: true,

  // have a common namespace used around the full app
  ns: ["common"],
  defaultNS: "common",
  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false
  }
});

export default i18n;