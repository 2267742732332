import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Keyboard from "react-simple-keyboard";
import { useTranslation, Trans } from "react-i18next";
import appIcon from "../assets/img/faviconicon.png";
import appBackground from "../assets/img/bg42.png";

import MainMenu from "./MainMenu";

import {Container, Row, Col} from 'react-bootstrap';
//import "./assets/sass/photon.scss";


import "react-simple-keyboard/build/css/index.css";



export default function Home() {
  const {t, i18n} = useTranslation();


  const [dzFontFamily, setDzFontFamily] = useState((localStorage.getItem('lng')==="dz")? ((localStorage.getItem('font'))? localStorage.getItem('font'): "wangdi29"):"");
  return (
      <Row>
        <Col>
          <div className="welcome">
            <div className={`title `+dzFontFamily}>
            {t('welcome')}
            </div>
            <img src={appIcon}></img>
          </div>
          <img src={appBackground} className="backgroundimage"/>
        </Col>
      </Row>
  );
}
