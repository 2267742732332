import React, { useRef, useState, Suspense } from "react";
import { Link } from "react-router-dom";
import Keyboard from "react-simple-keyboard";
import { useTranslation } from "react-i18next";
import {Container, Row, Col} from 'react-bootstrap';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/scss/react-flags-select.scss';


export default function LanguageSwitcher(props) {
  const {t, i18n} = useTranslation();

  const fontFamilyClass = props.fontFamily;

  const onSelectFlag = (countryCode) => {
    if(countryCode == "US"){
      i18n.changeLanguage('en');
      localStorage.setItem('lng', "en");
     // setFontFamilyClass("");
      props.changeFont();
    }
    else{
      i18n.changeLanguage('dz');
      localStorage.setItem('lng', "dz");
     // setFontFamilyClass("");
      props.changeFont();
    }
  }
  const selectedCountry = (localStorage.getItem('lng')==="en")? "US":"BT";
  

  return (
    <Suspense fallback="loading">
          <div className={`language-switcher `+fontFamilyClass}>
          <label>{t('language')}</label>
          <ReactFlagsSelect 
              countries={["BT", "US"]}
              defaultCountry={selectedCountry}
              customLabels={{"BT":"རྫོང་ཁ།", "US":"English"}}
              onSelect={onSelectFlag}
          />
          </div>
    </Suspense>
  );
}
