//returns string of keys
import keygroups from "./keygroups.json";
import keymaps from "./keymaps.json";

export const keyboardLayoutSelector = (character) => {

    
    const normalkeys = keygroups.normalkeys;
    const shiftkeys = keygroups.shiftkeys;
    const altkeys = keygroups.altkeys;
    const altshiftkeys = keygroups.altshiftkeys;

    let keyboardstatus = null;

    for(var i=0; i<normalkeys.length; i++){
        if(character == normalkeys[i]){
            keyboardstatus = "normalkeys";
            break;
        }
        else{
            continue;
        }
    }  
    if(keyboardstatus == null){
        for(var i=0; i<shiftkeys.length; i++){
            if(character == shiftkeys[i]){
                keyboardstatus = "shiftkeys";
                break;
            }
            else{
                continue;
            }
        }
    }

    if(keyboardstatus == null){
        for(var i=0; i<altkeys.length; i++){
            if(character == altkeys[i]){
                keyboardstatus = "altkeys";
                break;
            }
            else{
                continue;
            }
        }
    }
    if(keyboardstatus == null){
        for(var i=0; i<altshiftkeys.length; i++){
            if(character == altkeys[i]){
                return "altshiftkeys";
                break;
            }
            else{
                continue;
            }
        }
    }
    return keyboardstatus;
};

export const LeftRightSelection = (character) => {
    const rightkeys = keygroups.rightkeys;
    const leftkeys = keygroups.leftkeys;

    var keygroup = "left";

    for(var i=0; i<rightkeys.length; i++){
        if(character == rightkeys[i]){
            keygroup = "right";
            break;
        }
        else{
            continue;
        }
    }
    return keygroup;
};

export const LeftImageFile = (character) => {

    const keyboardlayout =  keyboardLayoutSelector(character);
    let imageFilename = "left-hand";

    if(keyboardlayout != null){
        const little = keymaps[keyboardlayout].left.little;
        const ring = keymaps[keyboardlayout].left.ring;
        const middle = keymaps[keyboardlayout].left.middle;
        const index = keymaps[keyboardlayout].left.index;
        const thumb = keymaps[keyboardlayout].left.thumb;

        switch(keyboardlayout){
            case "normalkeys":
                if(little.includes(character)){
                    imageFilename = "left-pinky";
                }
                if(ring.includes(character)){
                    imageFilename = "left-ring";
                }
                if(middle.includes(character)){
                    imageFilename = "left-middle";
                }
                if(index.includes(character)){
                    imageFilename = "left-index";
                }
                if(thumb.includes(character)){
                    imageFilename = "left-thumb";
                }
            break;
            case "shiftkeys":
                if(little.includes(character)){
                    imageFilename = "left-pinky";
                }
                else if(ring.includes(character)){
                    imageFilename = "left-ring";
                }
                else if(middle.includes(character)){
                    imageFilename = "left-middle";
                }
                else if(index.includes(character)){
                    imageFilename = "left-index";
                }
                else if(thumb.includes(character)){
                    imageFilename = "left-thumb";
                }
                else{
                    imageFilename = "shift-left";
                }
            break;
            case "altkeys":
                if(little.includes(character)){
                    imageFilename = "left-pinky";
                }
                else if(ring.includes(character)){
                    imageFilename = "left-ring";
                }
                else if(middle.includes(character)){
                    imageFilename = "left-middle";
                }
                else if(index.includes(character)){
                    imageFilename = "left-index";
                }
                else if(thumb.includes(character)){
                    imageFilename = "left-thumb";
                }
                
            break;
            case "altshiftkeys":
                if(little.includes(character)){
                    imageFilename = "left-pinky";
                }
                else if(ring.includes(character)){
                    imageFilename = "left-ring";
                }
                else if(middle.includes(character)){
                    imageFilename = "left-middle";
                }
                else if(index.includes(character)){
                    imageFilename = "left-index";
                }
                else if(thumb.includes(character)){
                    imageFilename = "left-thumb";
                }
                break;
            default:
                imageFilename = "left-hand";
                break;
        }
    }
    return imageFilename;
};

export const RightImageFile = (character) => {

    const keyboardlayout =  keyboardLayoutSelector(character);

    let imageFilename = "right-hand";

    if(keyboardlayout != null){
        const little = keymaps[keyboardlayout].right.little;
        const ring = keymaps[keyboardlayout].right.ring;
        const middle = keymaps[keyboardlayout].right.middle;
        const index = keymaps[keyboardlayout].right.index;
        const thumb = keymaps[keyboardlayout].right.thumb;


        switch(keyboardlayout){
            case "normalkeys":
                if(little.includes(character)){
                    imageFilename = "right-pinky";
                }
                if(ring.includes(character)){
                    imageFilename = "right-ring";
                }
                if(middle.includes(character)){
                    imageFilename = "right-middle";
                }
                if(index.includes(character)){
                    imageFilename = "right-index";
                }
                if(thumb.includes(character)){
                    imageFilename = "right-thumb";
                }
            break;
            case "shiftkeys":
                if(little.includes(character)){
                    imageFilename = "right-pinky";
                }
                else if(ring.includes(character)){
                    imageFilename = "right-ring";
                }
                else if(middle.includes(character)){
                    imageFilename = "right-middle";
                }
                else if(index.includes(character)){
                    imageFilename = "right-index";
                }
                else if(thumb.includes(character)){
                    imageFilename = "right-thumb";
                }
                else{
                    imageFilename = "shift-right";
                }
            break;
            case "altkeys":
                if(little.includes(character)){
                    imageFilename = "alt-right-pinky";
                }
                else if(ring.includes(character)){
                    imageFilename = "alt-right-ring";
                }
                else if(middle.includes(character)){
                    imageFilename = "alt-right-middle";
                }
                else if(index.includes(character)){
                    imageFilename = "alt-right-index";
                }
                else if(thumb.includes(character)){
                    imageFilename = "right-thumb";
                }
                
            break;
            case "altshiftkeys":
                if(little.includes(character)){
                    imageFilename = "alt-shift-right-pinky";
                }
                else if(ring.includes(character)){
                    imageFilename = "alt-shift-right-ring";
                }
                else if(middle.includes(character)){
                    imageFilename = "alt-shift-right-middle";
                }
                else if(index.includes(character)){
                    imageFilename = "alt-shift-right-index";
                }
                else if(thumb.includes(character)){
                    imageFilename = "right-thumb";
                }
                break;
            default:
                break;
        }
    }
    return imageFilename;
};