import React, { useState, useEffect, useRef } from 'react';
import Image from 'react-bootstrap/Image';

import { LeftImageFile } from "./HandsHelper";



export default function LeftFingers(props) {

    let character = props.character;

    // if(character == "ག"){
    //     leftFingerSelection  = leftMiddle;
    //     // setLeftKeyFinger(leftMiddle);
    //     // rightFingerSelection  = rightHand;
    // }
    // if(character == "་"){
    //     leftFingerSelection  = leftThumb;
    //     // rightFingerSelection  = rightThumb;
    // }

    // let fingername = props.fingername.toLowerCase()+".png";
    const fingerImageName = LeftImageFile(character);

    return (
        <>
        {fingerImageName &&
            <Image src={require(`../assets/img/hands/${fingerImageName}.png`)} className="left-hand">
            </Image>
        }
        </>
    )
};