
import React, { useState, useEffect, useRef } from 'react';
import Image from 'react-bootstrap/Image';

import { RightImageFile } from "./HandsHelper";


export default function RightFingers(props) {

    let character = props.character;

    const fingerImageName = RightImageFile(character);

    return (
        <>
        {fingerImageName &&
            <Image src={require(`../assets/img/hands/${fingerImageName}.png`)} className="right-hand">
            </Image>
        }
        </>
    )
};